export default {
    namespaced: true,

    state: {
        maxTime: 15,
        startSpeed: 1,
        maxSpeed: 20
    },

    getters: {
        maxTime: state => state.maxTime,
        startSpeed: state => state.startSpeed,
        maxSpeed: state => state.maxSpeed
    },

    mutations: {
        setGameData: ( state, payload ) =>
        {
            for( let item in payload )
            {
                state[ item ] = payload[ item ];
            }
        }
    },
    actions: {
        getValues: context =>
        {
            let data = {
                maxTime: window._rootData.maxTime,
                startSpeed: window._rootData.startSpeed,
                maxSpeed: window._rootData.maxSpeed
            };

            context.commit( 'setGameData', data );
        }
    }
};
