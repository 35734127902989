<template>
    <div id="app">
        <ynk-layout>
            <transition mode="out-in"
                        @enter="getAnimateIn"
                        @leave="getAnimateOut"
                        :css="false"
                        appear>
                <router-view :key="$route.path" ref="currentView"></router-view>
            </transition>
        </ynk-layout>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';
</style>

<script>
    import { TimelineMax } from '@/vendor';
    import Main from '@/app/layouts/Main';

    export default {
        name: 'App',

        components: {
            'ynk-layout': Main
        },

        data()
        {
            return {
                sounds: [
                    {
                        name: 'click',
                        src: 'static/sounds/click.wav',
                        soundInstance: null,
                        volume: 1
                    },
                    {
                        name: 'lose',
                        src: 'static/sounds/lose.wav',
                        soundInstance: null,
                        volume: 1
                    },
                    {
                        name: 'win',
                        src: 'static/sounds/win.wav',
                        soundInstance: null,
                        volume: 1
                    }
                ]

            };
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );
            this.onResize();

            createjs.Sound.initializeDefaultPlugins();
            createjs.Sound.alternateExtensions = [ 'mp3' ];

            for( let sound of this.sounds )
            {
                createjs.Sound.registerSound( sound.src, sound.name, 20 );
            }
        },

        methods: {
            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
            },

            getAnimateIn( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateIn === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateIn(), 0 );
                }
            },

            getAnimateOut( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateOut === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateOut(), 0 );
                }
            }
        }
    };
</script>
