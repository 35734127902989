<template>
    <div :class="{ 'main-layout': true, [( !$route.name ? 'landing' : $route.name.toLowerCase()) + '-route']: true, 'no-prize': noPrize }">
        <div class="header-wrapper">
            <img class="de-logo" src="/static/img/de-logo.png" />
        </div>
        <div class="content">
            <slot></slot>
        </div>

        <div class="footer-wrapper"></div>
    </div>
</template>

<style lang="scss" scoped>
    .main-layout{
        .header-wrapper{
            text-align:center;
            padding-top:4.25%;

            img{
                width:24.25%;
            }
        }
    }
</style>

<script>
    export default {
        computed: {
            score()
            {
                return this.$store.getters['entry/all'].score;
            },

            noPrize()
            {
                if( this.$route.name && this.$route.name.toLowerCase() === 'result' && this.score < 40 )
                {
                    return true;
                }

                return false;
            }
        },

        mounted()
        {
            // console.log( this.$route );
        }
    };
</script>
