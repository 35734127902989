import EntryProxy from '@/app/proxies/EntryProxy';

export default {
    namespaced: true,
    state: {
        entry: {
            attempt: 0,
            score: 10,
            feedbackQuestion: null
        },
        leaderboard: []
    },

    getters: {
        all: state => state.entry,
        attempt: state => state.entry.attempt,
        leaderboard: state => state.leaderboard
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        },

        updateAttempt: state =>
        {
            state.entry.attempt++;
        },

        setLeaderboard: ( state, payload ) =>
        {
            state.leaderboard = payload;
        }
    },

    actions: {
        newAttempt: context =>
        {
            context.commit( 'updateAttempt' );
        },

        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        getLeaderboard: context =>
        {
            return new EntryProxy()
                .all()
                .then( response =>
                {
                    context.commit( 'setLeaderboard', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        save: ( context, payload ) =>
        {
            context.commit( 'assign', payload );

            return new EntryProxy()
                .create( context.getters.all )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        update: ( context, payload ) =>
        {
            context.commit( 'assign', payload );

            payload._method = 'PUT';

            return new EntryProxy()
                .update( context.getters.all.id, payload )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        }
    }
};
