import Vue from 'vue';
import Vuex from 'vuex';

import entry from './modules/entry';
import game from './modules/game';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        entry,
        game
    },

    state: {

    },

    getters: {

    },

    mutations: {
        initStore( state )
        {
            // Check if the ID exists
            if( localStorage.getItem( 'store' ) )
            {
                this.replaceState(
                    Object.assign( state, JSON.parse( window.localStorage.getItem( 'store' ) ) )
                );
            }
        }
    },

    actions: {
        initStore( context )
        {
            context.commit( 'initStore' );
        }
    }
});
